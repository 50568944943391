import logo from './logo.svg';
import './App.css';
import RicknMortyComponent from './components/RicknMortyComponent/RicknMortyComponent';

function App() {
  return (

        <RicknMortyComponent />

  );
}

export default App;
